import { icons, resume, resume_link, title } from "./Constants";
import "./Footer.scss";

export const Footer = () => {
    return (
        <div className="Footer">
            <p className="Footer__Title">{title}</p>
            <a href={resume_link} target="_blank" rel="noreferrer">{resume}</a>
            <div className="Footer__Icons">
                {icons.map((item) => (
                    <a href={item.link} target="_blank" rel="noreferrer">
                        <item.icon />
                    </a>
                ))}
            </div>
        </div>
    )
}