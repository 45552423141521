import { useState } from "react";
import { Loader } from "../Loader/Loader";
import { Overlay } from "../Overlay/Overlay";
import { title, navigation, occupation } from "./Constants";

import "./Homepage.scss";

export const Homepage = () => {

    const [loader, setLoader] = useState<boolean>(true);

    const video_url = "https://s3.ca-central-1.amazonaws.com/pphilip.com/homepage/homepage_video.mp4";

    const setOnLoadedData = () => {
        setLoader(false)
    }

    return (
        <div className="Homepage">
            <video 
                loop 
                autoPlay 
                muted
                onLoadedData={setOnLoadedData}
                style={{ opacity: !loader ? 1 : 0}}
            >
                <source src={video_url} type="video/mp4"/>
                {"An Error Occured"}
            </video>

            <Overlay opacity={0.9} />

            <div className="Homepage__Content">
                <p className="Homepage__Content__Title">{title}</p>
                <p className="Homepage__Content__Heading">{occupation}</p>
                <div className="Homepage__Content__Navigation">
                    {navigation.map((item, index) => (
                        <a key={index} href={item.link}>{item.text}</a>
                    ))}
                </div>
            </div>

            {loader && (
                <Loader />
            )}
        </div>
    )
}