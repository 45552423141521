import { UserOutlined, MailOutlined } from '@ant-design/icons';

export const title = "Send me a message"

export const submitFormText = "Send"

export const placeholder_message = "Message"

export const fields = [
    {
        placeholder: "Name",
        icon: <UserOutlined />
    },
    {
        placeholder: "Email",
        icon: <MailOutlined />
    }
]

export const error_email = "Invalid email address."

export const error = "This field cannot be empty."