/* eslint-disable react/jsx-no-target-blank */
import { Tag } from 'antd';

import { backend_stack, cloud_stack, frontend_stack, PersonalProjectBody, StackInterface } from "../../../../../../Constants/TypescriptConstants";

import "./PersonalProjectContainer.scss";

type Props = {
    data:PersonalProjectBody
}

export const PersonalProjectContainer:React.FC<Props> = (props) => {

    const getStackType = (stack:string):string => {
        if (Object.values(frontend_stack).includes(stack as unknown as frontend_stack)) return "geekblue"
        else if ((Object.values(backend_stack).includes(stack as unknown as backend_stack))) return "purple"
        else if ((Object.values(cloud_stack).includes(stack as unknown as cloud_stack))) return "volcano"
        return "gold"
    }

    const { hrefUrl, imageSrc, imageAlt, title, description, stack } = props.data 
    const combinedStack = [...stack.frontend, ...stack.backend, ...stack.cloud]

    return (
        <div className="PersonalProjectContainer">
            <a 
                href={hrefUrl} 
                target="_blank"
                rel="noreferrer"
                className="PersonalProjectContainer__Image"
            >
                <img src={imageSrc} alt={imageAlt}/>
            </a>
            <div className="PersonalProjectContainer__Content">
                <p className="PersonalProjectContainer__Content__Title">{title}</p>
                <p>{description}</p>
                <div className="PersonalProjectContainer__Content__Chips">
                    {combinedStack.map((item:string) => (
                        <Tag color={getStackType(item)}>{item}</Tag>
                    ))}
                </div>
                <a href={hrefUrl} target="_blank">View</a>
            </div>
        </div>
    )
}