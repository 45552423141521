export const title = <>Paul Philip <span>|</span> Portfolio</>

export const occupation = "Software Engineer"

export const navigation = [
    {
        text: "ABOUT ME",
        link: "/about"
    },
    {
        text: "PERSONAL PROJECTS",
        link: "/personalProjects"
    },
    {
        text: "EMPLOYERS",
        link: "/employers"
    },
    {
        text: "CONTACT",
        link: "/contact"
    }
    // {
    //     text: "CHALLENGE",
    //     link: "/mg"
    // },
]