import { Employers } from "../../Components/About/Components/Employers/Employers"
import { Footer } from "../../Components/Footer/Footer"
import { Navbar } from "../../Components/Navbar/Navbar"

export const Pages_Experience = () => {
    return (
        <>
            <Navbar />
            <Employers />
            <Footer />
        </>
    )
}