import { Tag } from 'antd';

import { backend_stack, cloud_stack, EmployersBody, frontend_stack } from "../../../../../Constants/TypescriptConstants";

import "./EmployersContainer.scss";

type Props = {
    data:EmployersBody
}

export const EmployersContainer:React.FC<Props> = (props) => {

    const getStackType = (stack:string):string => {
        if (Object.values(frontend_stack).includes(stack as unknown as frontend_stack)) return "geekblue"
        else if ((Object.values(backend_stack).includes(stack as unknown as backend_stack))) return "purple"
        else if ((Object.values(cloud_stack).includes(stack as unknown as cloud_stack))) return "volcano"
        return "gold"
    }

    const { title, description, imageSrc, imageAlt, hrefUrl, stack, company, location, date, imageWidth } = props.data
    const combinedStack = [...stack.frontend, ...stack.backend, ...stack.cloud]

    return (
        <div className="EmployersContainer">
            <a
                href={hrefUrl} 
                target="_blank"
                rel="noreferrer"
                className="EmployersContainer__Image"
            >
                <img 
                    src={imageSrc} 
                    alt={imageAlt}
                    width={imageWidth}
                />
            </a>
            <div className="EmployersContainer__Content">
                <p className="EmployersContainer__Content__Title">{title}</p>
                {company && <p>{company}</p>}
                <p className="EmployersContainer__Content__Sub">{location}</p>
                <p className="EmployersContainer__Content__Sub">{date}</p>
                <p>{description}</p>
                <div className="EmployersContainer__Content__Chips">
                    {combinedStack.map((item:string) => (
                        <Tag color={getStackType(item)}>{item}</Tag>
                    ))}
                </div>
            </div>
        </div>
    )
}