import { APP_SUBDOMAIN_TYPING_SPEED_TEST, WHEN_IS_MY_NEXT_HOLIDAY_URL } from "../../../../../Constants/APIConstants"
import { backend_stack, cloud_stack, frontend_stack, PersonalProjectBody } from "../../../../../Constants/TypescriptConstants"

export const title = "Personal Projects"

export const personalProjects:PersonalProjectBody[] = [
    {
        title: "When Is My Next Holiday",
        description: "Web app that shows upcoming holidays based on your selected location",
        imageSrc: "https://s3.ca-central-1.amazonaws.com/pphilip.com/about/whenismynextholiday_thumbnail.png",
        imageAlt: "When Is My Next Holiday",
        hrefUrl: WHEN_IS_MY_NEXT_HOLIDAY_URL,
        stack: {
            frontend: [frontend_stack.REACT, frontend_stack.TYPESCRIPT, frontend_stack.JAVASCRIPT, frontend_stack.CSS],
            backend: [backend_stack.NODE],
            cloud: [cloud_stack.AWS_AMPLIFY, cloud_stack.AWS_EC2]
        }
    },
    {
        title: "Typing Speed Test",
        description: "Web app that saves your typing speed",
        imageSrc: "https://s3.ca-central-1.amazonaws.com/pphilip.com/experience/keyboard.jpg",
        imageAlt: "Typing Speed Test",
        hrefUrl: APP_SUBDOMAIN_TYPING_SPEED_TEST,
        stack: {
            frontend: [frontend_stack.REACT, frontend_stack.TYPESCRIPT, frontend_stack.JAVASCRIPT, frontend_stack.SCSS],
            backend: [backend_stack.NODE],
            cloud: [cloud_stack.AWS_AMPLIFY, cloud_stack.AWS_EC2, cloud_stack.AWS_RDS]
        }
    }
]