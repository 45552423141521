import "./Overlay.scss";

type Props = {
    opacity?:number
}

export const Overlay:React.FC<Props> = (props) => {
    return (
        <div style={{ opacity: props.opacity ? props.opacity : 0.9 }} className="Overlay"></div>
    )
}