import { About } from "../../Components/About/About"
import { Footer } from "../../Components/Footer/Footer"
import { Navbar } from "../../Components/Navbar/Navbar"

export const Pages_About = () => {
    return (
        <>
            <Navbar />
            <About />
            <Footer />
        </>
    )
}