import { Personal } from "../../Components/About/Components/Personal/Personal"
import { Footer } from "../../Components/Footer/Footer"
import { Navbar } from "../../Components/Navbar/Navbar"

export const Pages_PersonalProjects = () => {
    return(
        <>
            <Navbar />
            <Personal />
            <Footer />
        </>
    )
}