import { useState } from 'react';
import { Card } from 'antd'

import { Personal } from '../Personal/Personal';
import { CardBody } from '../../../../Constants/TypescriptConstants';
import { cards } from './Constants';
import { Link } from 'react-router-dom';

import "./AboutCards.scss"

export const AboutCards = () => {

    const { Meta } = Card;

    return (
        <div className="AboutCards">
            {cards.map((item:CardBody, index:number) => (
                <Link to={item.link}>
                    <Card
                        key={index}
                        hoverable
                        cover={<img src={item.background} alt={item.title} />}
                        className="ant-card-about"
                    >
                        <Meta title={item.title} description={item.description} />
                    </Card>
                </Link>

            ))}
        </div>
    )
}