import { Dna } from 'react-loader-spinner'
import "./Loader.scss";

export const Loader = () => {
    return (
        <div className="Loader">
            {/* Loader Icon */}
            <div className="Loader__Icon">
                <Dna
                    height="80"
                    width="80"
                    ariaLabel="loading"
                />
            </div>
            <p>Loading</p>
        </div>
    )
}