import { useState } from "react";

import { AboutCards } from "./Components/AboutCards/AboutCards";
import { intro, profile_image, title } from "./Constants";

import "./About.scss"
import { Loader } from "../Loader/Loader";

export const About = () => {

    const [documentReady, setDocumentReady] = useState<boolean>(false)
    const url = "https://s3.ca-central-1.amazonaws.com/pphilip.com/about/About_Backdrop.png"

    const handleOnLoad = () => {
        setDocumentReady(true)
    }

    return (
        <div 
            className="About"
            // style={{
            //     background: "#000000",
            //     backgroundImage: `url(${url})`,
            //     backgroundRepeat: "no-repeat",
            //     backgroundPosition: "center",
            //     backgroundSize: "cover",
            //     backgroundAttachment: "fixed"
            // }}
        >
            <p className="About__Title">{title}</p>

            <div className="About__Container">
                <AboutCards />
                <div className="About__Container__Intro">
                    <img src={profile_image} alt={"Profile-Main"}/>
                    <div className="About__Container__Intro__Wrapper">
                        <p>{intro}</p>
                    </div>
                </div>
            </div>

            {!documentReady && <Loader />}
            <img
                className="hide"
                src={url}
                alt="Loader" 
                onLoad={handleOnLoad} 
            />
        </div>
    )
}