import { PersonalProjectBody } from '../../../../Constants/TypescriptConstants';
import { PersonalProjectContainer } from './Components/PersonalProjectContainer/PersonalProjectContainer';
import { personalProjects, title } from './Constants';

import "./Personal.scss";

export const Personal = () => {
    return (
        <div className="Personal">
            <div className="Personal__Wrapper">
                <p className="Personal__Wrapper__Title">{title}</p>
                <div className="Personal__Wrapper__Container">
                    {personalProjects.map((item:PersonalProjectBody) => (
                        <PersonalProjectContainer data={item} />
                    ))}
                </div>
            </div>
        </div>
    )
}