export const navigation = [
    {
        title: "HOME",
        link: "/"
    },
    {
        title: "ABOUT",
        link: "/about"
    },
    {
        title: "PERSONAL PROJECTS",
        link: "/personalProjects"
    },
    {
        title: "EMPLOYERS",
        link: "/employers"
    },
    {
        title: "CONTACT ME",
        link: "/contact"
    }
]