import { useState } from "react";
import { Input, Button } from 'antd';
import { MessageOutlined } from '@ant-design/icons';
import { useMediaQuery } from "react-responsive"
import { ToastContainer, toast } from 'react-toastify';
import validator from "validator";

import { title, fields, submitFormText, error_email, error, placeholder_message } from "./Constants";
import { MessageBody } from "../../Constants/TypescriptConstants";
import { API_BASE_URL, PATH_CONTACT } from "../../Constants/APIConstants";
import { Loader } from "../Loader/Loader";

import "./Contact.scss"

interface InputErrors {
    name:string,
    email:string,
    message:string
}

export const Contact = () => {
    
    const [isAPILoading, setIsAPILoading] = useState(false)
    const messageInitialState = {name: "", email: "", content: ""}
    const [message, setMessage] = useState<MessageBody>(messageInitialState)
    const inputErrorsInitialState = {name: "", email: "", message: ""}
    const [inputErrors, setInputErrors] = useState<InputErrors>(inputErrorsInitialState)

    const handleNameChange = (e:any) => {
        setMessage({
            ...message,
            name: e.target.value
        })
    }

    const handleEmailChange = (e:any) => {
        setMessage({
            ...message,
            email: e.target.value
        })
    }

    const handleContentChange = (e:any) => {
        setMessage({
            ...message,
            content: e.target.value
        })
    }

    const validateFields = () => {
        !validator.isEmail(message.email)
            ? setInputErrors((inputErrors) => ({ ...inputErrors, email: error_email }))
            : setInputErrors((inputErrors) => ({ ...inputErrors, email: "" }))
        message.name === "" 
            ? setInputErrors((inputErrors) => ({ ...inputErrors, name: error }))
            : setInputErrors((inputErrors) => ({ ...inputErrors, name: "" }))
        message.content === ""
            ? setInputErrors((inputErrors) => ({ ...inputErrors, message: error }))
            : setInputErrors((inputErrors) => ({ ...inputErrors, message: "" }))
        return (message.name && message.content && validator.isEmail(message.email))
    }

    const handleSubmitForm = async () => {
        const areFieldsValid = validateFields()
        if (areFieldsValid) {
            setIsAPILoading(true)
            const messageSentNotification = toast.loading("Just a moment...")
            const bodyForAPI = {
                name: message.name,
                contact: message.email,
                message: message.content
            }
            try {
                await fetch(API_BASE_URL + PATH_CONTACT, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    method: 'post',
                    body: JSON.stringify(bodyForAPI)
                }).then(() => {
                    toast.update(messageSentNotification, {render: "Thanks! Message sent.", type: "success", isLoading: false})
                    setIsAPILoading(false)
                })
            }
            catch(e) {
                console.log("/contact - submitFormError", e)
                toast.update(messageSentNotification, {render: "An error occured. Please reload the page and try again", type: "error", isLoading: false});
                setIsAPILoading(false)
            }
        }
    }

    const mapIndexToInputFunctions = [
        handleNameChange,
        handleEmailChange,
        handleContentChange
    ]

    const { TextArea } = Input
    const tablet = useMediaQuery({ query: "(max-width: 780px)"})
    const phone = useMediaQuery({ query: "(max-width: 500px)"})
    const inputTextSize = 
        tablet ? "middle" :
        phone ? "small" : "large"

    return (
        <div className="Contact">
            <p className="Contact__Title">{title}</p>

            <div className="Contact__Form">
                {fields.map((field, index:number) => (
                    <div className="Contact__Form__Container">
                        <div className="Contact__Form__Container__Field-wrapper">
                            <>{field.icon}</>
                            <Input 
                                placeholder={field.placeholder}
                                size={inputTextSize} 
                                status={
                                    index === 0
                                        ? inputErrors.name ? "error" : ""
                                        : inputErrors.email ? "error" : ""
                                }
                                onChange={mapIndexToInputFunctions[index]}
                            />
                        </div>
                        {index === 0 && (
                            <p className="Contact__Form__Container__Error-message">{inputErrors.name}</p>
                        )}
                        {index === 1 && (
                            <p className="Contact__Form__Container__Error-message">{inputErrors.email}</p>
                        )}
                    </div>
                ))}

                <div className="Contact__Form__Container__Field-wrapper Textarea">
                    <MessageOutlined />
                    <TextArea 
                        showCount 
                        maxLength={1000} 
                        rows={8}
                        status={inputErrors.message ? "error" : ""}
                        placeholder={inputErrors.message ? "Message (" + inputErrors.message + ")" : placeholder_message}
                        onChange={mapIndexToInputFunctions[2]}
                    />
                </div> 

                <div className="Contact__Form__Button">
                    <Button 
                        type="primary" 
                        disabled={isAPILoading} 
                        onClick={handleSubmitForm}
                    >
                        {submitFormText}
                    </Button>
                </div>

                <ToastContainer 
                    position="top-right"
                    autoClose={3000}
                    closeOnClick
                    rtl={false}
                    theme="light"
                />

                {isAPILoading && (
                    <Loader />
                )}
            </div>
        </div>
    )
}