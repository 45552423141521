import { frontend_stack, backend_stack, cloud_stack, EmployersBody } from "../../../../../Constants/TypescriptConstants";
import LOGO_NIELSEN from "../Logos/nielsen.png";
// import LOGO_P2 from "../Logos/p2.png";
import LOGO_SMSCS from "../Logos/test.png";
import LOGO_AEROTRENDS from "../Logos/aerotrends.png";
import LOGO_TIICKER from "../Logos/tiicker.png";
import LOGO_WC from "../Logos/wcr.png";
import LOGO_DMSI from "../Logos/dmsi.png";

export const title = "Experience";

export const title_current = "Current Employment"

export const title_past = "Previous Employment"

export const employers_current:EmployersBody[] = [
    {
        title: "Software Engineer - Full Time",
        company: "Nielsen",
        location: "New York, NY (Remote)",
        description: [
            "Working on a web application for the company"
        ],
        imageSrc: LOGO_NIELSEN,
        imageAlt: "Nielsen - Logo",
        hrefUrl: "https://www.nielsen.com/",
        stack: {
            frontend: [frontend_stack.REACT, frontend_stack.TYPESCRIPT, frontend_stack.JAVASCRIPT, frontend_stack.SCSS],
            backend: [],
            cloud: [cloud_stack.AWS]
        },
        imageWidth: 200,
        date: "June 2022 - Present"
    }
    // {
    //     title: "Lead Developer - Self Employed",
    //     company: "",
    //     location: "",
    //     description: [
    //         "I create internal web applications and websites for small businesses",
    //     ],
    //     imageSrc: LOGO_P2,
    //     imageAlt: "P^2 - Logo",
    //     hrefUrl: "https://www.pphilip.com",
    //     stack: {
    //         frontend: [frontend_stack.REACT, frontend_stack.TYPESCRIPT, frontend_stack.JAVASCRIPT, frontend_stack.SCSS],
    //         backend: [backend_stack.NODE],
    //         cloud: [cloud_stack.AWS_AMPLIFY, cloud_stack.AWS_EC2, cloud_stack.AWS_RDS]
    //     },
    //     imageWidth: 200,
    //     date: "May 2021 - Present"
    // },
]

export const employers_previous:EmployersBody[] = [
    {
        title: "Lead Developer - Independent Contractor",
        company: "SMS Corporate Services",
        location: "Kuala Lumpur, Malaysia (Remote)",
        description: [
            "Created an informational website."
        ],
        imageSrc: LOGO_SMSCS,
        imageAlt: "SMSCS - Logo",
        hrefUrl: "https://www.smscorporateservices.info/",
        stack: {
            frontend: [frontend_stack.REACT, frontend_stack.TYPESCRIPT, frontend_stack.JAVASCRIPT, frontend_stack.CSS],
            backend: [backend_stack.NODE],
            cloud: [cloud_stack.AWS_AMPLIFY, cloud_stack.AWS_EC2, cloud_stack.AWS_S3]
        },
        imageWidth: 200,
        date: "June 2022 - August 2022"
    },
    {
        title: "Lead Developer - Independent Contractor",
        company: "Aerotrends Aviation",
        location: "Dallas, Texas (Remote)",
        description: [
            "Created a quotation system and login application."
        ],
        imageSrc: LOGO_AEROTRENDS,
        imageAlt: "Aerotrends - Logo",
        hrefUrl: "https://aerotrends-aviation.com/",
        stack: {
            frontend: [frontend_stack.REACT, frontend_stack.TYPESCRIPT, frontend_stack.JAVASCRIPT, frontend_stack.CSS],
            backend: [backend_stack.NODE, backend_stack.PSQL],
            cloud: [cloud_stack.AWS_LAMBDA, cloud_stack.AWS_RDS, cloud_stack.AWS_AMPLIFY, cloud_stack.AWS_EC2, cloud_stack.AWS_S3]
        },
        imageWidth: 200,
        date: "June 2021 - December 2021"
    },
    {
        title: "Software Engineer - Independent Contractor",
        company: "WhiteCastle Roofing",
        location: "Lincoln, Nebraska",
        description: [
            "Worked with a lead engineer to build and host web applications for the company."
        ],
        imageSrc: LOGO_WC,
        imageAlt: "WhiteCastle - Logo",
        hrefUrl: "https://www.whitecastleroofing.com/",
        stack: {
            frontend: [frontend_stack.VUE, frontend_stack.JAVASCRIPT, frontend_stack.CSS],
            backend: [backend_stack.NODE, backend_stack.MYSQL],
            cloud: [cloud_stack.AWS_EC2]
        },
        imageWidth: 200,
        date: "February 2021 - April 2022"
    },
    {
        title: "Software Engineer - Internship",
        company: "Tiicker",
        location: "Detroit, Michigan (Remote)",
        description: [
            "Developed new features for a trading platform and mantained the application."
        ],
        imageSrc: LOGO_TIICKER,
        imageAlt: "Tiicker - Logo",
        hrefUrl: "https://www.tiicker.com/",
        stack: {
            frontend: [frontend_stack.REACT, frontend_stack.TYPESCRIPT, frontend_stack.JAVASCRIPT, frontend_stack.LESS],
            backend: [backend_stack.GRAPHQL, backend_stack.PSQL],
            cloud: [cloud_stack.AWS_EC2, cloud_stack.DOCKER]
        },
        imageWidth: 200,
        date: "September 2020 - December 2020"
    },
    {
        title: "Full Stack Developer - Internship",
        company: "DMSi",
        location: "Omaha, Nebraska (Remote)",
        description: [
            "Developed an Excel parser in the form of a web application."
        ],
        imageSrc: LOGO_DMSI,
        imageAlt: "DMSi - Logo",
        hrefUrl: "https://www.dmsi.com/",
        stack: {
            frontend: [frontend_stack.REACT, frontend_stack.JAVASCRIPT, frontend_stack.CSS],
            backend: [backend_stack.NODE, backend_stack.MYSQL],
            cloud: [cloud_stack.AWS_EC2, cloud_stack.AWS_AMPLIFY, cloud_stack.AWS_S3]
        },
        imageWidth: 200,
        date: "August 2019 - May 2020"
    }
]