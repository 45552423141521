import { Contact } from "../../Components/Contact/Contact"
import { Footer } from "../../Components/Footer/Footer"
import { Navbar } from "../../Components/Navbar/Navbar"

export const Pages_Contact = () => {
    return (
        <>
            <Navbar />
            <Contact />
            <Footer />
        </>
    )
}