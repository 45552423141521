import { Link } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import { slide as Menu } from 'react-burger-menu';

import { navigation } from "./Constants";

import "./Navbar.scss";

export const Navbar = () => {

    const tablet = useMediaQuery({ query: '(max-width: 800px)' })

    return (
        <>
            {!tablet ? (
                <div className="Navbar">
                    <div className="Navbar__Links">
                        {navigation.map((item, index) => (
                            <Link key={index} to={item.link}>{item.title}</Link>
                        ))}
                    </div>
                </div>
            ) : (
                    <Menu
                        noOverlay
                    >
                        {navigation.map((item, index) => (
                            <Link key={index} to={item.link}>{item.title}</Link>
                        ))}
                    </Menu>
            )}
        </>

    )
}