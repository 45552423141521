import { Footer } from "../../Components/Footer/Footer";
import { Homepage } from "../../Components/Homepage/Homepage";

export const Pages_Homepage = () => {
    return (
        <>
            <Homepage />
            <Footer />
        </>
    )
}