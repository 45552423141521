import { LinkedinOutlined, InstagramOutlined, MailOutlined } from '@ant-design/icons';

export const title = "Paul Philip"

export const resume = "Resume"

export const resume_link = "https://s3.ca-central-1.amazonaws.com/pphilip.com/paul_resume.pdf"

export const icons = [
    {
        icon: () => <MailOutlined />,
        link: "mailto:paul@pphilip.com"
    },
    {
        icon: () => <LinkedinOutlined />,
        link: "https://www.linkedin.com/in/paulphilip96/"
    },
]
