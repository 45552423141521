export const cards = [
    // {
    //     title: " CONTRACTS",
    //     description: "Internal web applciations & websites I create for my clients",
    //     background: "https://s3.ca-central-1.amazonaws.com/pphilip.com/experience/keyboard.jpg"
    // },
    {
        title: "EMPLOYERS",
        description: "All my employers and contracts I've completed",
        background: "https://s3.ca-central-1.amazonaws.com/pphilip.com/experience/building.jpg",
        link: "/employers"
    },
    {
        title: "PERSONAL PROJECTS",
        description: "Some apps I made for fun",
        background: "https://s3.ca-central-1.amazonaws.com/pphilip.com/experience/project.jpg",
        link: "/personalProjects"
    }
]