import { employers_current, employers_previous, title, title_current, title_past } from "./Constants";
import { EmployersContainer } from "./EmployersContainer/EmployersContainer";
import { EmployersBody } from "../../../../Constants/TypescriptConstants";

import "./Employers.scss";

export const Employers = () => {
    return (
        <div className="Employers">
            <div className="Employers__Wrapper">
                <p className="Employers__Wrapper__Title">{title}</p>
                <div className="Employers__Wrapper__Container">
                    {/* <p className="Employers__Wrapper__Container__Heading">{title_current}</p> */}
                    {employers_current.map((item:EmployersBody) => (
                        <EmployersContainer data={item} />
                    ))}
                </div>
                <div className="Employers__Wrapper__Container">
                    {/* <p className="Employers__Wrapper__Container__Heading">{title_past}</p> */}
                    {employers_previous.map((item:EmployersBody) => (
                        <EmployersContainer data={item} />
                    ))}
                </div>
            </div>
        </div>
    )
}