import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet";

import { ParentMeta } from "./Metatags/Homepage/ParentMeta";
import { Pages_Homepage as Homepage } from "./Pages/Homepage/homepage";
import { Pages_About as About } from "./Pages/About/about";
import { Pages_Contact as Contact } from "./Pages/Contact/contact";
import { Pages_Experience as Experience } from "./Pages/Experience/experience";
import { Pages_PersonalProjects as PersonalProjects } from "./Pages/PersonalProjects/personalProjects";

import './App.scss';
import 'antd/dist/antd.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'rodal/lib/rodal.css';

function App() {
  return (
    <BrowserRouter>
      <Helmet>
        <title>Paul Philip | Portfolio</title>
        <ParentMeta />
      </Helmet>

      <div className="App">
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/employers" element={<Experience />} />
          <Route path="/personalProjects" element={<PersonalProjects />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
