//Enums
export enum frontend_stack {
    TYPESCRIPT = "TypeScript",
    JAVASCRIPT = "JavaScript",
    SCSS = "SCSS",
    CSS = "CSS",
    LESS = "LESS",
    REACT = "React",
    VUE = "VueJS"
}

export enum backend_stack {
    NODE = "NodeJS",
    PSQL = "PostgreSQL",
    MYSQL = "MySQL",
    GRAPHQL = "GraphQL"
}

export enum cloud_stack {
    AWS_AMPLIFY = "AWS Amplify",
    AWS_EC2 = "AWS EC2",
    AWS_RDS = "AWS RDS",
    AWS_LAMBDA = "AWS Lambda",
    AWS_S3 = "AWS S3",
    AWS = "AWS",
    DOCKER = "DOCKER"
}

//Interfaces
export type StackInterface = {
    frontend:frontend_stack[],
    backend:backend_stack[],
    cloud:cloud_stack[]
}

export type MessageBody = {
    name:string,
    email:string,
    content:string
}

export type CardBody = {
    title:string,
    description:string,
    background:string,
    link:string
}

export type PersonalProjectBody = {
    title:string,
    description:string,
    imageSrc:string,
    imageAlt:string,
    hrefUrl:string,
    stack:StackInterface
}

export type EmployersBody = Omit<PersonalProjectBody, "description"> & {
    company:string,
    description:string[],
    location:string,
    imageWidth?:number,
    date:string
}